import React, { useContext } from "react";
import CurrentUserContext from "../context/current-user";
import { useLoaderData } from "react-router-dom";
import { IDevrioProjectData } from "../interfaces";
import WorkflowProjectsItem from "./projects-index-item";

const WorkflowProjectsIndex = (): JSX.Element => {
  const projectsList = useLoaderData() as IDevrioProjectData[];
  console.log(projectsList);
  const currentUser = useContext(CurrentUserContext);
  if (currentUser) {
    return (
      <div className="workflow-container">
        <ul className="workflow-projects-list">
          {projectsList.map((project: IDevrioProjectData, index: number): JSX.Element => {
            return <WorkflowProjectsItem index={index} project={project} key={`projects-item-${index}`} />;
          })}
        </ul>
      </div>
    );
  } else {
    return <></>;
  }
};

export default WorkflowProjectsIndex;
