import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import CurrentUserContext from "../context/current-user";
import { getAuth, signOut } from "firebase/auth";
import { getApp } from "firebase/app";

const WorkflowMenu = (): JSX.Element => {
  const currentUser = useContext(CurrentUserContext);
  return (
    <nav className="workflow-nav">
      <ul className="workflow-menu">
        <li className="menu-item">
          <NavLink end to="/workflow-tester">
            Devrio Workflow Tester
          </NavLink>
        </li>
        {currentUser ? (
          <>
            <li className="menu-item">
              <NavLink end to="/workflow-tester/projects">
                Projects
              </NavLink>
            </li>
            <li
              className="menu-item"
              onClick={(): void => {
                signOut(getAuth(getApp()));
              }}
            >
              <span>Sign Out</span>
            </li>
          </>
        ) : (
          ""
        )}
      </ul>
    </nav>
  );
};

export default WorkflowMenu;
