import { createContext } from "react";
import { IDevrioProjectPayload, IWorkflowValues } from "../interfaces";

export const getDefaultProjectPayload = (): IDevrioProjectPayload => {
    return {
        cityName: '',
        emailAddress: '',
        fullName: '',
        phoneNumber: '',
        postalCode: '',
        projectName: '',
        projectNotes: '',
        stateName: '',
        streetAddress: '',
        showsEverything: '',
        serviceCategories: []
    };
};

const WorkflowContext = createContext<IWorkflowValues>({
    projectId: '',
    projectData: getDefaultProjectPayload(),
    modelFile: null,
    modelPath: '',
    imagesFile: null,
    imagesPath: '',
    setProjectData: (_: IDevrioProjectPayload): void => {},
    setModelPath: (_: string): void => {},
    setImagesPath: (_: string): void => {},
    setImagesFile: (_: File | null): void => {},
    setModelFile: (_: File | null): void => {},
    setProjectId: (_: string): void => {},
});

export default WorkflowContext;