import React from "react";
import { useLoaderData } from "react-router-dom";
import { IDevrioProjectData } from "../interfaces";
import ProjectGallery from "../projects/modal-gallery";

const WorkflowProjectsSingle = (): JSX.Element => {
  const projectData = useLoaderData() as IDevrioProjectData;
  const { protocol, host, search } = window.location;
  const publicURL = `${protocol}//${host}/${projectData.id}${search}`;
  return (
    <div className="workflow-workspace">
      <div className="workflow-content">
        <dl>
          <dt>Project Name:</dt>
          <dd>
            <strong>{projectData.projectName}</strong>
          </dd>
        </dl>
        <dl>
          <dt>Creation Date:</dt>
          <dd>
            <strong>
              {new Date(projectData.created.seconds * 1000).toLocaleString()}
            </strong>
          </dd>
        </dl>
        {projectData.expiresOn ? (
          <dl>
            <dt>Expiration Date:</dt>
            <dd>
              <strong>
                {new Date(
                  projectData.expiresOn.seconds * 1000
                ).toLocaleString()}
              </strong>
            </dd>
          </dl>
        ) : (
          ""
        )}
        <dl>
          <dt>Project Notes:</dt>
          <dd>
            <strong>{projectData.projectNotes}</strong>
          </dd>
        </dl>
        <dl>
          <dt>Service Categories:</dt>
          {projectData.serviceCategories.map(
            (category: string, index): JSX.Element => {
              return (
                <dd key={`category-${index}`}>
                  <strong>{category}</strong>
                </dd>
              );
            }
          )}
        </dl>
        <dl>
          <dt>Shows Everything:</dt>
          <dd>
            <strong>
              {projectData.showsEverything
                ? projectData.showsEverything
                : "UNDEFINED: project created before field added."}
            </strong>
          </dd>
        </dl>
        <dl>
          <dt>Public URL:</dt>
          <dd>
            <strong>
              <a
                href={publicURL}
                title={`${projectData.projectName}`}
                target="_blank"
                rel="noreferrer"
              >
                {publicURL}
              </a>
            </strong>
          </dd>
        </dl>
        <ProjectGallery displayGallery={true} project={projectData} />
      </div>
      <div className="workflow-content">
        <h4>Preview:</h4>
        <iframe
          src={publicURL}
          className="workflow-iframe"
          title={projectData.projectName}
        ></iframe>
      </div>
    </div>
  );
};

export default WorkflowProjectsSingle;
