import { LoaderFunctionArgs } from "react-router-dom";
import firebase from "../firebase";
import { IDevrioProjectData } from "../interfaces";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";

export const loader_ProjectsList = async (): Promise<IDevrioProjectData[]> => {
  return new Promise((resolve: Function): void => {
    onAuthStateChanged(getAuth(), async (user: User | null): Promise<void> => {
      if (user) {
        const projectsList = await firebase.listProjects();
        resolve(projectsList);
      } else {
        resolve([])
      }
    });
  });
};

export const loader_ProjectsSingle = async ({ params }: LoaderFunctionArgs): Promise<IDevrioProjectData | null> => {
  return await firebase.getSingleProject(params.projectId as string);
};
