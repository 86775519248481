import React, { useContext } from "react";
import CurrentUserContext from "../context/current-user";
import { Outlet } from "react-router-dom";

const WorkflowProjects = (): JSX.Element => {
  const currentUser = useContext(CurrentUserContext);
  if (currentUser) {
    return <Outlet />;
  } else {
    return <></>;
  }
};

export default WorkflowProjects;
