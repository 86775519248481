import React from "react";
import { Link } from "react-router-dom";
import { IWorkflowProjectItemProps } from "../interfaces";

const HOURS_PER_DAY = 24;
const MILLIS_PER_HOUR = 3600000;

const WorkflowProjectsItem = ({
  project,
  index,
}: IWorkflowProjectItemProps): JSX.Element => {
  const daysToExpiry = 7;
  const created = new Date(project.created.seconds * 1000);
  const expired = new Date(
    created.getTime() + daysToExpiry * HOURS_PER_DAY * MILLIS_PER_HOUR
  );
  const isExpired = expired.getTime() < new Date().getTime();
  return (
    <li
      key={`project-item-${index}`}
      className={`workflow-gallery-item project-item-status-${project.status} ${
        isExpired ? "expired" : ""
      }`}
    >
      <Link to={`/workflow-tester/projects/${project.id}`}>
        <div className="workflow-gallery-preview">
          <img src={project.imageURLs[0] || ""} alt="Preview" />
        </div>
        <div className="workflow-gallery-item-title">{project.projectName}</div>
        <div className="workflow-gallery-item-date">
          {new Date(project.created.seconds * 1000).toLocaleString()}
        </div>
      </Link>
    </li>
  );
};

export default WorkflowProjectsItem;
