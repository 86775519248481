import React from "react";
import { LightboxContext, ILightboxContextValue } from "../context/lightbox";

export interface IGalleryItemProps {
  url: string;
  index: number;
  projectName: string;
  displayGallery: boolean;
}

const GalleryItem = ({
  url,
  projectName,
  index,
  displayGallery,
}: IGalleryItemProps): JSX.Element => {
  return (
    <LightboxContext.Consumer>
      {({ setLightboxImage }: ILightboxContextValue): JSX.Element => {
        return (
          <div
            className="brand3d-gallery-item"
            onClick={(): void => {
              setLightboxImage(index);
            }}
          >
            <div className="brand3d-gallery-thumbnail">
              {displayGallery ? <img src={url} alt={`${projectName}`} /> : ""}
            </div>
          </div>
        );
      }}
    </LightboxContext.Consumer>
  );
};

export default GalleryItem;
