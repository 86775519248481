export const getStoragePath = (url: string): string => {
  const matches = /http.+\/o\/(.+)\?.+/i.exec(url);
  if (matches) {
    return decodeURIComponent(matches[1]);
  }
  return url;
};

export const getImageNumber = (path: string): number => {
  const matches = /.+?(\d+)\.jpe?g/i.exec(path);
  if (matches) {
    return parseInt(matches[1], 10);
  }
  return 0;
};
