import React, { MouseEvent, TouchEvent, useContext, useState } from "react";
import { LightboxContext } from "../context/lightbox";

export interface ILightboxProps {
  imageUrls: string[];
}

export interface Coord {
  x: number;
  y: number;
}

export const getTouchDistance = (evt: TouchEvent<HTMLImageElement>): number => {
  const touch1 = evt.changedTouches.item(0);
  const touch2 = evt.changedTouches.item(1);
  return Math.hypot(touch1.pageX - touch2.pageX, touch1.pageY - touch2.pageY);
};

export const getDistance = (
  x1: number,
  y1: number,
  x2: number,
  y2: number
): number => {
  return Math.hypot(x1 - x2, y1 - y2);
};

const MAX_SCALE_FACTOR = 3;

export const LightboxView = ({ imageUrls }: ILightboxProps): JSX.Element => {
  const { lightboxImage, setLightboxImage } = useContext(LightboxContext);
  const [initialPosition, setInitialPosition] = useState<Coord>({ x: 0, y: 0 });
  const [initialDistance, setInitialDistance] = useState<number>(0);
  const [swipe, setSwipe] = useState<number>(0);
  const [isZoomed, setIsZoomed] = useState<boolean>(false);
  const [imageScale, setImageScale] = useState<number>(1);
  const [currentScale, setCurrentScale] = useState<number>(1);
  const [lastTranslation, setLastTranslation] = useState<Coord>({
    x: 0,
    y: 0,
  });
  const [imageTranslation, setImageTranslation] = useState<Coord>({
    x: 0,
    y: 0,
  });
  return (
    <div
      className={`brand3d-lightbox ${
        lightboxImage >= 0 ? "active" : "inactive"
      }`}
    >
      <div
        className="brand3d-lightbox-image"
        onMouseUp={(event: MouseEvent<HTMLDivElement>): void => {
          const image = event.currentTarget
            .getElementsByTagName("img")
            .item(0) as HTMLImageElement;
          const matches = /translate.+?(-?\d)px.+(-?\d)px/i.exec(
            image.style.transform
          ) as string[];
          console.log(matches);
        }}
        ///////////////////////////////////////////////////////////////////////
        /////////////////////////// ON DOUBLE CLICK ///////////////////////////
        ///////////////////////////////////////////////////////////////////////
        onDoubleClick={(): void => {
          setImageScale(1);
          setCurrentScale(1);
          setInitialDistance(0);
          setInitialPosition({ x: 0, y: 0 });
          setLastTranslation({ x: 0, y: 0 });
          setImageTranslation({ x: 0, y: 0 });
        }}
        ///////////////////////////////////////////////////////////////////////
        /////////////////////////// ON TOUCH START ////////////////////////////
        ///////////////////////////////////////////////////////////////////////
        onTouchStart={(event: TouchEvent<HTMLImageElement>): void => {
          if (event.touches.length === 1) {
            setInitialPosition({
              x: event.touches[0].clientX,
              y: event.touches[0].clientY,
            });
          } else if (event.touches.length === 2) {
            const { clientX: aX, clientY: aY } = event.touches[0];
            const { clientX: bX, clientY: bY } = event.touches[1];
            setInitialDistance(getDistance(aX, aY, bX, bY));
          }
          setIsZoomed(currentScale > 1);
          event.preventDefault();
        }}
        ///////////////////////////////////////////////////////////////////////
        //////////////////////////// ON TOUCH MOVE ////////////////////////////
        ///////////////////////////////////////////////////////////////////////
        onTouchMove={(event: TouchEvent<HTMLImageElement>): void => {
          if (event.touches.length === 2 && initialDistance) {
            // Image is being zommed in or out
            const { clientX: aX, clientY: aY } = event.touches[0];
            const { clientX: bX, clientY: bY } = event.touches[1];
            const currentDistance = getDistance(aX, aY, bX, bY);
            const multiplier = currentDistance / initialDistance;
            setCurrentScale(
              Math.min(MAX_SCALE_FACTOR, Math.max(1, imageScale * multiplier))
            );
            if (currentScale === 1) {
              setLastTranslation({ x: 0, y: 0 });
              setImageTranslation({ x: 0, y: 0 });
            }
            setTimeout((): void => {
              setIsZoomed(currentScale > 1);
            }, 250);
          } else if (event.touches.length === 1 && isZoomed) {
            // Image is zoomed and dragged
            const image = event.currentTarget
              .getElementsByTagName("img")
              .item(0) as HTMLImageElement;
            const { offsetWidth, offsetHeight } = image;
            const { clientX, clientY } = event.touches[0];
            const deltaX = (clientX - initialPosition.x) / imageScale;
            const deltaY = (clientY - initialPosition.y) / imageScale;
            const tX = lastTranslation.x + deltaX;
            const tY = lastTranslation.y + deltaY;
            setImageTranslation({
              x: Math.min(Math.max(tX, offsetWidth / -2), offsetWidth / 2),
              y: Math.min(Math.max(tY, offsetHeight / -2), offsetHeight / 2),
            });
          } else if (event.touches.length === 1 && !isZoomed) {
            // Image swipe
            const { clientX } = event.touches[0];
            const { x } = initialPosition;
            setSwipe(clientX - x < 0 ? 1 : -1);
          }
          event.preventDefault();
        }}
        ///////////////////////////////////////////////////////////////////////
        //////////////////////////// ON TOUCH END /////////////////////////////
        ///////////////////////////////////////////////////////////////////////
        onTouchEnd={(event: TouchEvent<HTMLImageElement>): void => {
          if (swipe > 0) {
            setLightboxImage(
              lightboxImage === imageUrls.length - 1
                ? 0
                : Math.min(lightboxImage + 1, imageUrls.length - 1)
            );
          } else if (swipe < 0) {
            setLightboxImage(
              lightboxImage === 0
                ? imageUrls.length - 1
                : Math.max(0, lightboxImage - 1)
            );
          }
          if (isZoomed) {
            setLastTranslation({
              x: imageTranslation.x,
              y: imageTranslation.y,
            });
            setImageScale(currentScale);
            setInitialPosition({
              x: event.touches[0].clientX,
              y: event.touches[0].clientY,
            });
            setTimeout((): void => {
              setIsZoomed(currentScale > 1);
            }, 250);
          } else {
            setInitialDistance(0);
            if (imageScale === 1) {
              setImageScale(1);
              setCurrentScale(1);
              setInitialPosition({ x: 0, y: 0 });
              setLastTranslation({ x: 0, y: 0 });
            }
          }
          setSwipe(0);
        }}
      >
        <img
          src={imageUrls[lightboxImage]}
          style={{
            transform: `scale(${currentScale}) translate(${imageTranslation.x}px, ${imageTranslation.y}px)`,
          }}
          alt=""
        />
      </div>
      <div
        className="brand3d-lightbox-close brand3d-close-button"
        onClick={() => setLightboxImage(-1)}
      >
        <span className="icon icon-circle-close"></span>
      </div>
      {"ontouchstart" in window ? (
        ""
      ) : (
        <>
          <div
            className="brand3d-lightbox-previous"
            onClick={() => {
              if (lightboxImage === 0) {
                setLightboxImage(imageUrls.length - 1);
              } else {
                setLightboxImage(Math.max(lightboxImage - 1, 0));
              }
            }}
          >
            <span className="icon icon-circle-left"></span>
          </div>
          <div
            className="brand3d-lightbox-next"
            onClick={() => {
              if (lightboxImage === imageUrls.length - 1) {
                setLightboxImage(0);
              } else {
                setLightboxImage(
                  Math.min(lightboxImage + 1, imageUrls.length - 1)
                );
              }
            }}
          >
            <span className="icon icon-circle-right"></span>
          </div>
        </>
      )}
      <div className="brand3d-lightbox-counter">
        {lightboxImage + 1} / {imageUrls.length}
      </div>
    </div>
  );
};

export default LightboxView;
