import React from "react";
import { useLoaderData } from "react-router-dom";
import { IDevrioProjectData } from "../interfaces";
import ProjectsSingleView from "./single";

const ProjectsSingleFunctional = (): JSX.Element => {
  const project = useLoaderData() as IDevrioProjectData;
  return <ProjectsSingleView project={project} />;
};

export default ProjectsSingleFunctional;
