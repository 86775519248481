import React from "react";
import { IDevrioProjectData } from "../interfaces";

export interface IProjectNotesProps {
  project: IDevrioProjectData;
}

const ProjectsNotes = ({ project }: IProjectNotesProps): JSX.Element => {
  return (
    <div className="brand3d-project-notes">
      <div className="project-notes-title">
        <h3>Project Notes</h3>
      </div>
      <div className="project-notes-section">
        <p className="project-notes-content">
          {project.projectName || "No name provided"}
        </p>
      </div>
      <div className="project-notes-section">
        <p>
          <strong>Additional Notes</strong>
        </p>
        <p className="project-notes-content">
          {project.projectNotes || "No notes provided"}
        </p>
      </div>
      <div className="project-notes-section">
        <p>
          <strong>Project location</strong>
        </p>
        <p className="project-notes-content">
        {project.cityName && project.stateName ? `${project.cityName}, ${project.stateName}` : "Not available"}
        </p>
      </div>
      <div className="project-notes-section">
        <p>
          <strong>Does this scan show everything?</strong>
        </p>
        <p className="project-notes-content">
          {project.showsEverything ? project.showsEverything : 'Not available'}
        </p>
      </div>
      {/* <div className="project-notes-section">Notes</div> */}
    </div>
  );
};

export default ProjectsNotes;
