import React, { useEffect, useState } from "react";
import "./style.scss";
import CurrentUserContext from "../context/current-user";
import { Outlet } from "react-router-dom";
import WorkflowMenu from "./menu";
import WorkflowSingin from "./singin";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";

const isProduction = 'viewer.devrio.co' === window.location.hostname;

const WorkflowIndex = (): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  useEffect((): void => {
    onAuthStateChanged(getAuth(), (user: User | null): void => {
      setCurrentUser(user);
    });
  }, [currentUser, setCurrentUser]);
  if (isProduction) {
    return <></>;
  }
  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div className="workflow-root">
        <WorkflowMenu />
        {currentUser ? <Outlet /> : <WorkflowSingin />}
      </div>
    </CurrentUserContext.Provider>
  );
};

export default WorkflowIndex;
