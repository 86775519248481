import React from "react";
import P3dEmbedApi, { P3dEmbedApiPick, P3dMaterial } from "@p3d/embed-api";
import {
  IProjectsSingleProps,
  IProjectsSingleState,
  Measurement,
  ScreenCoord,
} from "../interfaces";
import ProjectGallery from "./modal-gallery";
import ProjectNotes from "./modal-notes";
import ModalHelp from "./modal-help";

const MARKER_DIAMETER = 8;
const MARKER_RADIUS = MARKER_DIAMETER / 2;
const LABEL_HALF_WIDTH = 14;
const LABEL_HALF_HEIGHT = 10;

class ProjectsSingleView extends React.Component<
  IProjectsSingleProps,
  IProjectsSingleState
> {
  protected iframeHooked = false;

  protected isMeasuring = false;

  protected canvasHooked = false;

  protected measurements: Measurement[] = [];

  protected canvasContext: CanvasRenderingContext2D | null = null;

  protected p3dApi: P3dEmbedApi = new P3dEmbedApi(
    document.createElement("iframe")
  );

  protected p3dIframe: React.RefObject<HTMLIFrameElement> =
    React.createRef<HTMLIFrameElement>();

  protected canvas: React.RefObject<HTMLCanvasElement> =
    React.createRef<HTMLCanvasElement>();

  public constructor(props: IProjectsSingleProps) {
    super(props);
    this.state = {
      canMeasure: false,
      toolbarActive: false,
      isMeasuring: false,
      // sequentialMode: false,
      history: [],
      displayGallery: false,
      displayNotes: false,
      displayHelp: false,
      displayControls: false,
      units: this.getUnits(),
    };
  }

  componentDidMount(): void {
    this.initEmbedAPI();
    this.initCanvas();
  }

  protected initEmbedAPI = async (): Promise<void> => {
    if (this.p3dIframe.current && !this.iframeHooked) {
      this.p3dApi = new P3dEmbedApi(this.p3dIframe.current, {
        onclick: this.handleClick,
        onhover: this.handleHover,
        hoverRate: 1000 / 60,
      });
      this.iframeHooked = true;
      this.p3dApi.setAllowCameraRecenter(false);
      this.p3dApi.setAllowCameraReset("if-outside");
      const materials = await this.p3dApi.listMaterials();
      materials.forEach((material: P3dMaterial): void => {
        material.doubleSided = false;
      });
    }
  };

  protected initCanvas = () => {
    const { current: canvas } = this.canvas;
    if (!this.canvasHooked && canvas) {
      this.canvasContext = canvas.getContext("2d");
      requestAnimationFrame(this.renderLoop);
      this.canvasHooked = true;
    }
    window.addEventListener("resize", this.resizeCanvas);
  };

  protected resizeCanvas = () => {
    const { current: canvas } = this.canvas;
    if (canvas) {
      this.drawAllMeasurements();
    }
  };

  protected renderLoop = async (): Promise<void> => {
    this.drawAllMeasurements();
    requestAnimationFrame(this.renderLoop);
  };

  protected drawAllMeasurements = async (): Promise<void> => {
    const projectedMeasurements = await this.projectAllMeasurements();
    const { current: canvas } = this.canvas;
    if (canvas && this.canvasContext) {
      this.resetCanvas(canvas);
      const { width, height } = canvas;
      this.canvasContext.clearRect(0, 0, width, height);
      projectedMeasurements.forEach(this.drawOneMeasurement);
    }
  };

  protected drawOneMeasurement = (screenCord: ScreenCoord): void => {
    const { startPoint, endPoint, measurement } = screenCord;
    const { x: sx, y: sy, z: sz } = startPoint;
    const { x: ex, y: ey, z: ez } = endPoint;
    // Do not draw when behind camera
    if (sz <= 0 && ez <= 0) {
      return;
    }
    const tx = sx - (sx - ex) / 2;
    const ty = sy - (sy - ey) / 2;
    // Draw this measurement
    this.drawMeasurementLine(sx, sy, ex, ey);
    this.drawMarkers(sx, sy, ex, ey);
    this.drawTextContainer(tx, ty);
    this.drawMeasurementText(tx, ty, measurement)
  }

  protected async projectAllMeasurements(): Promise<ScreenCoord[]> {
    const allMeasurementsReady = this.measurements.map(
      (m: Measurement): Promise<ScreenCoord> => {
        return this.asyncProjectToScreen(m);
      }
    );
    return Promise.all(allMeasurementsReady);
  }

  protected resetCanvas(canvas: HTMLCanvasElement): void {
    // Resize if needed
    const { innerHeight, innerWidth } = window;
    if (canvas.height !== innerHeight || canvas.width !== innerWidth) {
      canvas.height = innerHeight;
      canvas.width = innerWidth;
    }
  }

  protected drawMeasurementLine(sx: number, sy: number, ex: number, ey: number): void {
    const { canvasContext: ctx } = this;
    if (!ctx) {
      return;
    }
    ctx.lineWidth = 2;
    ctx.lineCap = "round";
    ctx.beginPath();
    ctx.setLineDash([2, 4]);
    ctx.strokeStyle = "rgba(0, 0, 0, 0.5)";
    ctx.moveTo(sx, sy);
    ctx.lineTo(ex, ey);
    ctx.stroke();
  }

  protected drawMarkers(sx: number, sy: number, ex: number, ey: number): void {
    const { canvasContext: ctx } = this;
    if (!ctx) {
      return;
    }
    // start marker
    ctx.beginPath();
    ctx.setLineDash([1, 1]);
    ctx.strokeStyle = "rgba(0, 0, 0, 0.5)";
    ctx.arc(sx, sy, MARKER_RADIUS, 0, Math.PI * 2, false);
    ctx.stroke();
    // end marker
    ctx.beginPath();
    ctx.setLineDash([1, 1]);
    ctx.strokeStyle = "rgba(0, 0, 0, 1)";
    ctx.arc(ex, ey, MARKER_RADIUS, 0, Math.PI * 2, false);
    ctx.stroke();
  }

  protected drawTextContainer(tx: number, ty: number): void {
    const { canvasContext: ctx } = this;
    if (!ctx) {
      return;
    }
    ctx.beginPath();
    ctx.moveTo(tx - LABEL_HALF_WIDTH, ty - LABEL_HALF_HEIGHT);
    ctx.lineTo(tx + LABEL_HALF_WIDTH, ty - LABEL_HALF_HEIGHT);
    ctx.arc(
      tx + LABEL_HALF_WIDTH,
      ty,
      LABEL_HALF_HEIGHT,
      Math.PI / -2,
      Math.PI / 2,
      false
    );
    ctx.lineTo(tx - LABEL_HALF_WIDTH, ty + LABEL_HALF_HEIGHT);
    ctx.arc(
      tx - LABEL_HALF_WIDTH,
      ty,
      LABEL_HALF_HEIGHT,
      Math.PI / 2,
      Math.PI * 1.5,
      false
    );
    ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
    ctx.fill();
  }

  protected drawMeasurementText(tx: number, ty: number, m: Measurement): void {
    const { canvasContext: ctx } = this;
    if (!ctx) {
      return;
    }
    const { units } = this.state;
    // const { measurement: m } = screenCord;
    let distanceMetric = Math.sqrt(
      (m.end.x - m.start.x) * (m.end.x - m.start.x) +
        (m.end.y - m.start.y) * (m.end.y - m.start.y) +
        (m.end.z - m.start.z) * (m.end.z - m.start.z)
    );
    ctx.font = "12px sans-serif";
    ctx.textAlign = "center";
    ctx.strokeStyle = "rgba(255, 255, 255, 1)";
    ctx.fillStyle = "white";
    const labelYPos = ty + LABEL_HALF_HEIGHT / 2;
    if (units === "imperial") {
      const feet = this.getDistanceImperial(distanceMetric);
      ctx.fillText(feet, tx, labelYPos);
    } else {
      ctx.fillText(`${distanceMetric.toFixed(2)}`, tx, labelYPos);
    }
  }

  protected getDistanceImperial(distance: number) {
    const mm = distance * 1000;
    const inches = mm / 25.4;
    const feet = Math.floor(inches / 12);
    const remInch = Math.floor(inches % 12);
    return `${feet ? feet + "' " : ""}${
      remInch ? Math.floor(remInch) + '"' : ""
    }`;
  }

  protected asyncProjectToScreen = async (
    measurement: Measurement
  ): Promise<ScreenCoord> => {
    const { start, end } = measurement;
    const { x: sx, y: sy, z: sz } = start;
    const { x: ex, y: ey, z: ez } = end;
    const points = await Promise.all([
      this.p3dApi.projectToScreen(sx, sy, sz),
      this.p3dApi.projectToScreen(ex, ey, ez),
    ]);
    return { startPoint: points[0], endPoint: points[1], measurement };
  };

  protected handleClick = async (event: P3dEmbedApiPick): Promise<void> => {
    const { measurements } = this;
    const { isMeasuring, history, canMeasure } = this.state;
    if (!canMeasure) {
      return;
    }
    let updatedMeasurements = [...measurements];
    const msSize = measurements.length;
    const lastIndex = Math.max(msSize - 1, 0);
    if (event.coordinates) {
      const start = event.coordinates;
      const end = event.coordinates;
      if (!isMeasuring) {
        // Save previous history state
        this.setState({ history: history.concat([[...measurements]]) });
        // create new measurement
        updatedMeasurements = updatedMeasurements.concat([{ start, end }]);
      } else {
        // `measurements` May become empty when flush or undo is used
        if (msSize === 0) {
          // Start new set of measurement when they're empty
          updatedMeasurements = [];
          updatedMeasurements[lastIndex] = { start, end };
        } else {
          // end of measurement, update end
          updatedMeasurements[lastIndex].end = event.coordinates;
        }
      }
      this.setState({ isMeasuring: !isMeasuring });
    } else if (isMeasuring) {
      this.setState({ isMeasuring: false });
      updatedMeasurements.pop(); // drop last measurement if click is outside the model
      history.pop();
      this.setState({ history: [...history] });
    }
    this.measurements = [...updatedMeasurements];
  };

  protected handleHover = (event: P3dEmbedApiPick): void => {
    const { isMeasuring } = this.state;
    let updatedMeasurements = [...this.measurements];
    const msSize = updatedMeasurements.length;
    const lastIndex = Math.max(msSize - 1, 0);
    if (event.coordinates && isMeasuring && msSize > 0) {
      updatedMeasurements[lastIndex].end = event.coordinates;
      this.measurements = [...updatedMeasurements];
    }
  };

  protected handleUndo = (): void => {
    const { history } = this.state;
    if (history.length > 0) {
      const previousHistory = [...history];
      const lastState = previousHistory.pop();
      if (lastState) {
        console.log("Last state", lastState);
        this.measurements = [...lastState];
      } else {
        console.log("History was empty", lastState);
        this.measurements = [];
      }
      this.setState({ history: previousHistory });
    }
  };
  protected handleFlushMeasurements = (): void => {
    const { history } = this.state;
    if (this.measurements.length > 0) {
      const newHistory = [...history];
      newHistory.push([...this.measurements]);
      this.measurements = [];
      this.setState({ history: newHistory });
    }
  };

  protected handleToggleGallery = (): void => {
    const { displayGallery, displayNotes: areNotesVisible } = this.state;
    const displayNotes = !displayGallery ? false : areNotesVisible;
    this.setState({ displayGallery: !displayGallery, displayNotes });
  };

  protected handleToggleNotes = (): void => {
    const { displayNotes, displayGallery: isGalleryVisible } = this.state;
    const displayGallery = !displayNotes ? false : isGalleryVisible;
    this.setState({ displayNotes: !displayNotes, displayGallery });
  };

  protected handleToggleHelp = (): void => {
    const { displayHelp } = this.state;
    this.setState({ displayHelp: !displayHelp });
  };

  protected handleControlsToggle = (): void => {
    const { displayControls } = this.state;
    this.setState({
      displayControls: !displayControls,
      canMeasure: false,
    });
  };

  protected handleUnitsToggle = (): void => {
    let { units } = this.state;
    units = units === "metric" ? "imperial" : "metric";
    window.localStorage.setItem('devrio-units', units);
    this.setState({ units });
  };

  protected getUnits(): 'imperial' | 'metric' {
    let units: 'imperial' | 'metric' = 'imperial';
    const savedUnit = window.localStorage.getItem('devrio-units');
    if (savedUnit === 'metric' || savedUnit === 'imperial') {
      units = savedUnit;
    } else {
      window.localStorage.setItem('devrio-units', units);
    }
    return units;
  }

  render(): JSX.Element {
    const { project } = this.props;
    const {
      displayGallery,
      displayNotes,
      canMeasure,
      displayHelp,
      displayControls,
      units,
    } = this.state;
    if (project && project.modelId) {
      return (
        <div className="brand3d-archtool-model">
          <iframe
            id="p3d-iframe"
            title="p3d.in embed view"
            ref={this.p3dIframe}
            className="brand3d-archtool-p3d-iframe"
            src={`https://p3d.in/e/${project.modelId}+shadeless+api+load+controls,border-hidden+bg-ffffffff`}
            seamless
            sandbox="allow-scripts allow-same-origin"
          />
          <canvas
            className="brand3d-archtool-measurements-canvas"
            id="brand3d-archtool-measurements-canvas"
            ref={this.canvas}
          ></canvas>
          <div className="brand3d-project-branding">
            <img src="/img/logo/devrio.svg" alt="Devrio Logo" />
          </div>
          <div
            className={`brand3d-project-gallery-container ${
              displayGallery ? "active" : ""
            }`}
          >
            <ProjectGallery project={project} displayGallery={displayGallery} />
            <div
              className="brand3d-gallery-close brand3d-close-button"
              onClick={() => this.setState({ displayGallery: false })}
            >
              <span className="icon icon-circle-close"></span>
            </div>
          </div>
          <div
            className={`brand3d-project-notes-container ${
              displayNotes ? "active" : ""
            }`}
          >
            <ProjectNotes project={project} />
            <div
              className="brand3d-notes-close brand3d-close-button"
              onClick={() => this.setState({ displayNotes: false })}
            >
              <span className="icon icon-circle-close"></span>
            </div>
          </div>

          {/* START TOOLBAR */}
          <div className={`brand3d-archtool-toolbar`}>
            <div
              className={`brand3d-archtool-control visible ${
                displayControls ? "active" : ""
              }`}
              title="Toggle tool bar"
            >
              <span
                className="icon icon-menu"
                onClick={this.handleControlsToggle}
              ></span>
            </div>
            <div
              className={`brand3d-archtool-control visible ${
                canMeasure ? "active" : "disabled"
              } ${displayControls ? "visible" : "hidden"}`}
              title="Toggle measurement edit controls"
            >
              <span
                className="icon icon-ruler"
                onClick={(): void => {
                  this.setState({ canMeasure: !canMeasure });
                }}
              ></span>
            </div>
            <div
              title="Toggle between metric and imperial units."
              className={`brand3d-archtool-control ${
                displayControls && canMeasure ? "visible" : "hidden"
              }`}
              onClick={this.handleUnitsToggle}
            >
              <span
                className={`unit-toggle ${
                  units === "metric" ? "visible" : "hidden"
                } metric`}
              >
                m
              </span>
              <span
                className={`unit-toggle ${
                  units === "imperial" ? "visible" : "hidden"
                } imperial`}
              >
                ft
              </span>
            </div>
            <div
              title="Undo the last measurement"
              className={`brand3d-archtool-control ${
                displayControls && canMeasure ? "visible" : "hidden"
              }`}
              onClick={this.handleUndo}
            >
              <span className="icon icon-undo"></span>
            </div>
            <div
              title="Click to flush all measurments"
              className={`brand3d-archtool-control ${
                displayControls && canMeasure ? "visible" : "hidden"
              }`}
              onClick={this.handleFlushMeasurements}
            >
              <span className="icon icon-trashcan"></span>
            </div>
            <div
              className={`brand3d-archtool-control ${
                displayControls && !canMeasure ? "visible" : "hidden"
              }`}
              onClick={this.handleToggleGallery}
              title="Click to display image gallery of project"
            >
              <span className="icon icon-images"></span>
            </div>
            <div
              className={`brand3d-archtool-control ${
                displayControls && !canMeasure ? "visible" : "hidden"
              }`}
              onClick={this.handleToggleNotes}
              title="Click to display the project notes"
            >
              <span className="icon icon-notes"></span>
            </div>
            <div
              className={`brand3d-archtool-control ${
                displayControls && !canMeasure ? "visible" : "hidden"
              }`}
              onClick={this.handleToggleHelp}
              title="Click to display the project notes"
            >
              <span className="icon icon-help"></span>
            </div>
          </div>
          {/* END TOOLBAR */}

          <div
            className={`brand3d-modal-help ${
              displayHelp ? "visible" : "hidden"
            }`}
          >
            <ModalHelp
              setDisplayHelp={(displayHelp: boolean) => {
                this.setState({ displayHelp });
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="brand3d-archtool-404">Error 404, model not found</div>
    );
  }
}

export default ProjectsSingleView;
