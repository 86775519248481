import React, { MouseEvent, useEffect, useState } from "react";
// import WorkflowMenu from "./menu";
import { getDefaultProjectPayload } from "./context";
import { IDevrioProjectPayload, IWorkflowLog } from "../interfaces";
import "./style.scss";
import {
  FormCategoriesSelect,
  FormFileInput,
  FormInputField,
  FormTextareaNotes,
} from "./form-fields";
import {
  sleep,
  step_ImageslFileIsReady,
  step_ModelFileIsReady,
  step_ProjectDataIsReady,
  step_ProvisionDatabaseEntry,
  step_TriggerPostProcessing,
  step_UploadImagesFile,
  step_UploadModelFile,
} from "./functions";

const WorkflowForm = (): JSX.Element => {
  const [projectPayload, setProjectPayload] = useState<IDevrioProjectPayload>(
    getDefaultProjectPayload()
  );
  const [status, setStatus] = useState<string>("pending");
  const [projectId, setProjectId] = useState("");
  const [modelFile, setModelFile] = useState<File | null>(null);
  const [imagesFile, setImagesFile] = useState<File | null>(null);
  const [projectName, setProjectName] = useState<string>("");
  const [projectNotes, setProjectNotes] = useState<string>("");
  const [serviceCategories, setServiceCategories] = useState<string[]>([]);
  const [fullName, setFullName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [streetAddress, setStreetAddress] = useState<string>("");
  const [stateName, setStateName] = useState<string>("");
  const [cityName, setCityName] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [showsEverything, setShowsEverything] = useState<string>('');
  const [mockupLog, setWorkflowLog] = useState<IWorkflowLog[]>([]);
  useEffect(() => {
    setProjectPayload({
      cityName,
      emailAddress,
      fullName,
      phoneNumber,
      postalCode,
      projectName,
      projectNotes,
      serviceCategories,
      stateName,
      streetAddress,
      showsEverything
    });
  }, [
    projectId,
    projectName,
    projectNotes,
    fullName,
    emailAddress,
    streetAddress,
    cityName,
    phoneNumber,
    stateName,
    postalCode,
    modelFile,
    imagesFile,
    serviceCategories,
    showsEverything
  ]);
  return (
    <div className="workflow-container">
      <div className="workflow-workspace">
        <div className="workflow-content">
          <h2>Data Form</h2>
          <form action="">
            <FormInputField
              fieldLabel="Project Name"
              fieldName="projectName"
              stateValue={projectName}
              stateCallback={setProjectName}
            />
            <FormTextareaNotes
              stateValue={projectNotes}
              stateCallback={setProjectNotes}
            />
            <FormCategoriesSelect
              stateCallback={setServiceCategories}
              stateValue={serviceCategories}
            />
            <hr />
            <FormInputField
              fieldName="fullName"
              fieldLabel="Full Name"
              stateValue={fullName}
              stateCallback={setFullName}
            />
            <FormInputField
              fieldName="phoneNumber"
              fieldLabel="Phone Number"
              stateValue={phoneNumber}
              stateCallback={setPhoneNumber}
            />
            <FormInputField
              fieldName="emailAddress"
              fieldLabel="Email Address"
              stateValue={emailAddress}
              stateCallback={setEmailAddress}
            />
            <FormInputField
              fieldName="streetAddress"
              fieldLabel="Street Address"
              stateValue={streetAddress}
              stateCallback={setStreetAddress}
            />
            <FormInputField
              fieldName="stateName"
              fieldLabel="State Name / Code"
              stateValue={stateName}
              stateCallback={setStateName}
            />
            <FormInputField
              fieldName="cityName"
              fieldLabel="City Name"
              stateValue={cityName}
              stateCallback={setCityName}
              />
              <FormInputField
                fieldName="postalCode"
                fieldLabel="Postal Code"
                stateValue={postalCode}
                stateCallback={setPostalCode}
              />
              <FormInputField
                fieldName="showsEverything"
                fieldLabel="Does this scan show everything?"
                stateValue={showsEverything}
                stateCallback={setShowsEverything}
              />

            <FormFileInput
              fieldLabel="Model File"
              fieldName="modelFile"
              stateCallback={setModelFile}
            />
            <FormFileInput
              fieldLabel="Images File"
              fieldName="imagesFile"
              stateCallback={setImagesFile}
            />
            <div className="workflow-form-section">
              <div>
                <button
                  type="button"
                  className="button"
                  onClick={(evt: MouseEvent<HTMLButtonElement>): void => {
                    evt.preventDefault();
                    setProjectName("Default Project Name");
                    setProjectNotes(
                      "Default project notes go here, this makes it easier to test"
                    );
                    setFullName("John Doe");
                    setPhoneNumber("+123 456 789");
                    setEmailAddress("johndoe@example.com");
                    setStreetAddress("Street Name North.");
                    setStateName("Georgia");
                    setCityName("Atlanta");
                    setPostalCode("12345");
                    setServiceCategories(["repairs", "trim", "roofing"]);
                  }}
                >
                  Fill With Mock Data
                </button>
                <button
                  type="submit"
                  className="button"
                  onClick={async (
                    evt: MouseEvent<HTMLButtonElement>
                  ): Promise<void> => {
                    evt.preventDefault();
                    setStatus("pending");
                    const localLog: IWorkflowLog[] = [];
                    const dataReady = await step_ProjectDataIsReady(
                      projectPayload
                    );
                    const modelFileReady = await step_ModelFileIsReady(
                      modelFile
                    );
                    const imageFileReady = await step_ImageslFileIsReady(
                      imagesFile
                    );
                    localLog.push(dataReady, modelFileReady, imageFileReady);
                    setWorkflowLog(localLog.concat());
                    if (
                      dataReady.status === "error" ||
                      modelFileReady.status === "error" ||
                      imageFileReady.status === "error"
                    ) {
                      return;
                    }
                    // STEP 1: PROVISION DATABASE ENTRY
                    const projectId = await step_ProvisionDatabaseEntry(
                      projectPayload
                    );
                    localLog.push({
                      message: projectId
                        ? "Successfully provisioned database entry"
                        : "Error when provisioning database entry",
                      status: projectId ? "success" : "error",
                    });
                    setWorkflowLog(localLog.concat());
                    if (!projectId) {
                      return;
                    }
                    setProjectId(projectId);
                    await sleep(500);

                    // STEP 2 UPLOADING FILES
                    const modelPath = `devrio/uploads/${projectId}/models/${Date.now()}.glb`;
                    console.log(
                      "uploading file",
                      projectId,
                      modelFile,
                      modelPath
                    );
                    const modelDidUpload = await step_UploadModelFile(
                      projectId,
                      modelFile,
                      modelPath
                    );
                    localLog.push(modelDidUpload);
                    setWorkflowLog(localLog.concat());
                    if (modelDidUpload.status === "error") {
                      return;
                    }
                    await sleep(500);

                    const imagesPath = `devrio/uploads/${projectId}/images/${Date.now()}.zip`;
                    const imagesDidUpload = await step_UploadImagesFile(
                      projectId,
                      imagesFile,
                      imagesPath
                    );
                    localLog.push(imagesDidUpload);
                    setWorkflowLog(localLog.concat());
                    if (imagesDidUpload.status === "error") {
                      return;
                    }
                    await sleep(500);
                    const postProcessingDone = await step_TriggerPostProcessing(
                      projectId,
                      modelPath,
                      imagesPath
                    );
                    localLog.push(postProcessingDone);
                    setWorkflowLog(localLog.concat());
                    setStatus("active");
                  }}
                >
                  Start Processing
                </button>
                <button
                  type="button"
                  className="button"
                  onClick={(evt: MouseEvent<HTMLButtonElement>): void => {
                    evt.preventDefault();
                    setProjectId("");
                    setStatus("pending");
                    setWorkflowLog([]);
                  }}
                >
                  Reset Everything
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="workflow-content">
          <h2>Progress</h2>
          <div className="workflow-processing">
            {mockupLog.map((log: IWorkflowLog, index: number): JSX.Element => {
              return (
                <div
                  className={`workflow-log ${log.status}`}
                  key={`workflow-log-${index}`}
                >
                  {log.message}
                </div>
              );
            })}
          </div>
        </div>
        <div className="workflow-content">
          <h2>Result</h2>
          {status === "active" ? (
            <div>
              <p>
                <a
                  href={`/${projectId}`}
                  target="_blank"
                  rel="noreferrer"
                >{`/${projectId}`}</a>
              </p>
              <iframe
                src={`/${projectId}`}
                title="Result of Process"
                className="workflow-iframe"
              ></iframe>
            </div>
          ) : (
            <p>Waiting for process completion</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkflowForm;
