import { createBrowserRouter } from "react-router-dom";
import { loader_ProjectsList, loader_ProjectsSingle } from "../loaders";
import WorkflowTesterIndex from "../workflow";
import ProjectsSingleFunctional from "../projects/single-functional";
import WorkflowForm from "../workflow/form";
import WorkflowProjectsIndex from "../workflow/projects-index";
import WorkflowProjectsSingle from "../workflow/projects-single";
import WorkflowProjects from "../workflow/projects";

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <></>,
  },
  {
    path: "workflow-tester",
    element: <WorkflowTesterIndex />,
    children: [
      {
        path: '',
        element: <WorkflowForm />
      },
      {
        path: 'projects',
        element: <WorkflowProjects />,
        children: [
          {
            path: '',
            element: <WorkflowProjectsIndex />,
            loader: loader_ProjectsList
          },
          {
            path: ':projectId',
            element: <WorkflowProjectsSingle />,
            loader: loader_ProjectsSingle
          }
        ]
      }
    ]
  },
  {
    path: "projects/:projectId",
    loader: loader_ProjectsSingle,
    element: <ProjectsSingleFunctional />,
  },
  {
    path: ":projectId",
    loader: loader_ProjectsSingle,
    element: <ProjectsSingleFunctional />,
  },
]);

export default AppRouter;
