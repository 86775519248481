import React, { useEffect, useState } from "react";
import { IDevrioProjectData } from "../interfaces";
import { LightboxContext } from "../context/lightbox";
import LightboxView from "./modal-lightbox";
import GalleryItem from "./gallery-item";
import { getImageNumber, getStoragePath } from "./functions";

export interface IProjectGalleryProps {
  project: IDevrioProjectData;
  displayGallery: boolean;
}

const ProjectGallery = ({
  project,
  displayGallery,
}: IProjectGalleryProps): JSX.Element => {
  const { projectName } = project;
  const [imageURLs, setImageUrls] = useState<string[]>([]);
  useEffect(() => {
    const imageURLs: string[] = project.imageURLs.sort(
      (a: string, b: string): number => {
        const aPath = getStoragePath(a);
        const bPath = getStoragePath(b);
        const aNum = getImageNumber(aPath);
        const bNum = getImageNumber(bPath);
        return aNum < bNum ? -1 : 1;
      }
    );
    setImageUrls(imageURLs);
  }, [setImageUrls, project]);
  const [lightboxImage, setLightboxImage] = useState(-1);
  return (
    <LightboxContext.Provider value={{ lightboxImage, setLightboxImage }}>
      <div className="brand3d-project-gallery">
        <h3 className="brand3d-gallery-title">Image gallery</h3>
        <div className="brand3d-gallery-grid">
          {imageURLs.map(
            (url: string, index: number): JSX.Element => (
              <GalleryItem
                url={url}
                projectName={projectName}
                key={`gallery-image-${index}`}
                index={index}
                displayGallery={displayGallery}
              />
            )
          )}
        </div>
        <LightboxView imageUrls={imageURLs} />
      </div>
    </LightboxContext.Provider>
  );
};

export default ProjectGallery;
