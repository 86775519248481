import React from "react";
import { RouterProvider } from "react-router-dom";
import AppRouter from "../router";

const Application = (): JSX.Element => {
  return (
    <div className="brand3d-application">
      <RouterProvider router={AppRouter}></RouterProvider>
    </div>
  );
};

export default Application;
