import {
  HttpsCallableResult,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { IDevrioProjectPayload } from "../interfaces";
import { IWorkflowLog } from "../interfaces";
import { getApp } from "firebase/app";
import { APIResponse } from "../../../functions/src/devrio/interfaces";
import { UploadResult, getStorage, ref, uploadBytes } from "firebase/storage";

export const sleep = (milliseconds: number): Promise<void> => {
  return new Promise<void>((resolve): void => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
};

export const step_ProjectDataIsReady = async (
  projectData: IDevrioProjectPayload
): Promise<IWorkflowLog> => {
  const { projectName = "" } = projectData;
  return {
    message: !!projectName ? "Project has a name" : "Data seems incomplete",
    status: !!projectName ? "success" : "error",
  };
};

export const step_ModelFileIsReady = async (
  modelFile: File | null
): Promise<IWorkflowLog> => {
  return {
    message: !!modelFile
      ? "Model File is ready"
      : "Model file seems to be absent",
    status: !!modelFile ? "success" : "error",
  };
};

export const step_ImageslFileIsReady = async (
  imagesFile: File | null
): Promise<IWorkflowLog> => {
  return {
    message: !!imagesFile
      ? "Images File is ready"
      : "Images file seems to be absent",
    status: !!imagesFile ? "success" : "error",
  };
};

export const step_ProvisionDatabaseEntry = async (
  projectData: IDevrioProjectPayload
): Promise<string> => {
  const functions = getFunctions(getApp());
  const cloudFunction = httpsCallable<IDevrioProjectPayload>(
    functions,
    "devrio_ProvisionDatabaseEntry"
  );
  const { data } = (await cloudFunction(
    projectData
  )) as HttpsCallableResult<APIResponse>;
  return data.projectData && data.projectData.projectId ? data.projectData.projectId : '';
};

export const step_UploadModelFile = async (
  projectId: string,
  modelFile: File | null,
  modelPath: string
): Promise<IWorkflowLog> => {
  console.log(projectId, modelFile);
  let success = false;
  if (projectId && modelFile) {
    const modelFileRef = ref(getStorage(getApp()), modelPath);
    success = await uploadBytes(modelFileRef, modelFile)
      .then(async (result: UploadResult) => {
        console.log("Successful upload", result.metadata, result.ref.fullPath);
        return true;
      })
      .catch((error: any) => {
        console.error("Error when uploading the images file", error);
        return false;
      });
  } else {
    
  }
  return {
    message: success
      ? "Model File was uploaded correctly"
      : "Model failed to upload",
    status: success ? "success" : "error",
  };
};

export const step_UploadImagesFile = async (
  projectId: string,
  file: File | null,
  imagesPath: string
): Promise<IWorkflowLog> => {
  let success = false;
  if (projectId && file) {
    const imagesFileRef = ref(getStorage(getApp()), imagesPath);
    success = await uploadBytes(imagesFileRef, file)
      .then(async (result: UploadResult) => {
        console.log("Successful upload", result.metadata, result.ref.fullPath);
        return true;
      })
      .catch((error: any) => {
        console.error("Error when uploading the images file", error);
        return false;
      });
  }
  return {
    message: success
      ? "Images were uploaded correctly"
      : "Failed to load images",
    status: success ? "success" : "error",
  };
};

export const step_TriggerPostProcessing = async (
  projectId: string,
  modelPath: string,
  imagesPath: string
): Promise<IWorkflowLog> => {
  console.log(projectId || "none", modelPath || "none", imagesPath || "none");
  const functions = getFunctions(getApp());
  // get the database entry post processing cloud function
  const devrio_PostProcessEntry = httpsCallable(
    functions,
    "devrio_PostProcessEntry"
  );
  // this is an asynchronous request, wait for it to be done
  const success = await devrio_PostProcessEntry({
    projectId,
    modelPath,
    imagesPath,
  })
    .then((response: HttpsCallableResult<any>): any => {
      console.log("Request successful", response.data);
      return true;
    })
    .catch((error) => {
      console.error("Error when calling devrio_PostProcessEntry", error);
      return false;
    });
  return {
    message: success
      ? "Post-processing is done correctly"
      : "Error when post-processing the entry",
    status: success ? "success" : "error",
  };
};
