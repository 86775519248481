import React, { Dispatch } from "react";

export interface IModalHelpProps {
  setDisplayHelp: Dispatch<boolean>;
}

const ModalHelp = ({ setDisplayHelp }: IModalHelpProps): JSX.Element => {
  return (
    <div className="brand3d-modal">
      <div className="brand3d-modal-title">
        Navigation Controls
        <div
          className="brand3d-help-close brand3d-close-button"
          onClick={() => setDisplayHelp(false)}
        >
          <span className="icon icon-circle-close"></span>
        </div>
      </div>
      <div className="brand3d-modal-content">
        <table className="brand3d-help-hints">
          <thead>
            <tr>
              <th></th>
              <th>Rotate</th>
              <th>Zoom</th>
              <th>Pan</th>
              <th>Focus</th>
              <th>Reset</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Mouse</td>
              <td>
                <span className="icon icon-lmb"></span>
              </td>
              <td>
                <span className="icon icon-mmb"></span>
              </td>
              <td>
                <span className="icon icon-rmb"></span>
              </td>
              <td>
                <span className="icon icon-lmb"></span>
              </td>
              <td>
                <span className="icon icon-double-click"></span>
              </td>
            </tr>
            <tr>
              <td>Key</td>
              <td>
                <span className="help-hint">Z</span>
              </td>
              <td>
                <span className="help-hint">X</span>
              </td>
              <td>
                <span className="help-hint">C</span>
              </td>
              <td>
                <span className="help-hint"></span>
              </td>
              <td>
                <span className="help-hint">R</span>
              </td>
            </tr>
            <tr>
              <td>Touch</td>
              <td>
                <span className="icon icon-touch-rotate"></span>
              </td>
              <td>
                <span className="icon icon-touch-zoom"></span>
              </td>
              <td>
                <span className="icon icon-touch-pan"></span>
              </td>
              <td>
                <span className="icon icon-single-tap"></span>
              </td>
              <td>
                <span className="icon icon-double-tap"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ModalHelp;
