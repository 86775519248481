import React, { ChangeEvent, Dispatch } from "react";

export interface IFormInputProps {
  fieldName: string;
  fieldLabel: string;
  stateValue: string;
  stateCallback: Dispatch<string>;
}

export interface IFormFileProps {
  fieldName: string;
  fieldLabel: string;
  // stateValue: string;
  stateCallback: Dispatch<File | null>;
}

export interface IFormCategoriesProps {
  stateValue: string[];
  stateCallback: Dispatch<string[]>;
}

export interface IFormTextareaProps {
  stateValue: string;
  stateCallback: Dispatch<string>;
}

export const FormInputField = ({ stateValue, stateCallback, fieldLabel, fieldName}: IFormInputProps): JSX.Element => {
  return (
    <div className="workflow-form-section">
      <label htmlFor={fieldName}>
        {fieldLabel}:
        <input
          type="text"
          name={fieldName}
          id={fieldName}
          value={stateValue}
          onChange={(evt: ChangeEvent<HTMLInputElement>): void =>
            stateCallback(evt.target.value)
          }
        />
      </label>
    </div>
  );
};

export const FormTextareaNotes = ({ stateValue, stateCallback }: IFormTextareaProps): JSX.Element => {
  return (
    <div className="workflow-form-section">
      <label htmlFor="projectNotes">
        Project Notes:
        <textarea
          name="projectNotes"
          id="projectNotes"
          value={stateValue}
          onChange={(evt: ChangeEvent<HTMLTextAreaElement>): void =>
            stateCallback(evt.target.value)
          }
        />
      </label>
    </div>
  );
};

export const FormCategoriesSelect = ({ stateValue, stateCallback }: IFormCategoriesProps): JSX.Element => {
  return (
    <div className="workflow-form-section">
      <label htmlFor="serviceCategories">
        Service Categories:
        <select
          name="serviceCategories"
          id="serviceCategories"
          multiple
          value={stateValue}
          onChange={(evt: ChangeEvent<HTMLSelectElement>): void => {
            const { target } = evt;
            const selectedValues = Array.from(
              target.selectedOptions
            ).map(
              (option: HTMLOptionElement): string => option.value
            );
            stateCallback(selectedValues);
          }}
        >
          <option value="Remodeling">Remodeling</option>
          <option value="Repairs">Repairs</option>
          <option value="Demolition">Demolition</option>
          <option value="Trim">Trim</option>
          <option value="Roofing">Roofing</option>
        </select>
      </label>
    </div>
  );
};

export const FormFileInput = ({ fieldLabel, fieldName, stateCallback}: IFormFileProps): JSX.Element => {
  return (
    <div className="workflow-form-section">
      <label htmlFor="modelFile">
        {fieldLabel}:
        <input
          multiple={false}
          type="file"
          name={fieldName}
          id={fieldName}
          onChange={(evt: ChangeEvent<HTMLInputElement>): void => {
            const { files } = evt.target;
            if (files && files.length) {
              stateCallback(files.item(0));
            }
          }}
        />
      </label>
    </div>
  );
}