// Import the functions you need from the SDKs you need
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
// import { Analytics, getAnalytics } from "firebase/analytics";
import {
  Firestore,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  connectFirestoreEmulator,
  doc,
  getDocFromServer,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import {
  FirebaseStorage,
  connectStorageEmulator,
  getStorage,
} from "firebase/storage";
import {
  Functions,
  connectFunctionsEmulator,
  getFunctions,
} from "firebase/functions";
import {
  Auth,
  GoogleAuthProvider,
  User,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { IDevrioProjectData } from "../interfaces";

const CONNECT_EMULATORS = false;
const IS_DEV_OR_LOCAL = process.env.NODE_ENV === "development";

class FirebaseService {
  public app: FirebaseApp;

  // private analytics: Analytics;

  public auth: Auth;

  public firestore: Firestore;

  public storage: FirebaseStorage;

  public functions: Functions;

  private googleProvider: GoogleAuthProvider;

  public currentUser: User | null = null;

  public constructor(config: FirebaseOptions) {
    this.app = initializeApp(config);
    // this.analytics = getAnalytics(this.app);
    this.auth = getAuth(this.app);
    this.firestore = getFirestore(this.app);
    this.storage = getStorage(this.app);
    this.googleProvider = new GoogleAuthProvider();
    this.functions = getFunctions(this.app);
    if (IS_DEV_OR_LOCAL && CONNECT_EMULATORS) {
      connectFunctionsEmulator(this.functions, "127.0.0.1", 5001);
      connectFirestoreEmulator(this.firestore, "127.0.0.1", 8080);
      connectStorageEmulator(this.storage, "127.0.0.1", 9199);
    }
    onAuthStateChanged(this.auth, (user: User | null) => {
      this.currentUser = user;
    });
  }

  public loginWithGoogle = async (): Promise<boolean> => {
    return await signInWithPopup(getAuth(this.app), this.googleProvider)
      .then((): boolean => {
        return true;
      })
      .catch((error: any): boolean => {
        console.log("Error when logging in", error);
        return false;
      });
  };

  public logout(): void {
    signOut(getAuth(this.app));
  }

  public listProjects = async (): Promise<IDevrioProjectData[]> => {
    const user = this.currentUser;
    if (!user) {
      return [];
    }
    return new Promise(async (resolve, reject) => {
      const coll = collection(this.firestore, "devrio_projects");
      const q = query(
        coll,
        where("owner", "==", user.uid),
        // where("status", "==", "active"),
        orderBy('created', 'desc'),
      );
      const docs = await getDocs(q)
        .then((result: QuerySnapshot) => {
          return result.docs;
        })
        .catch((error) => {
          console.log("Error when listing models", error);
          return [];
        });
      resolve(
        docs
          ? docs.map((snap: QueryDocumentSnapshot): IDevrioProjectData => {
              return Object.assign({}, snap.data(), {
                id: snap.id,
              }) as IDevrioProjectData;
            })
          : []
      );
    });
  };

  public getSingleProject = async (
    projectId: string
  ): Promise<IDevrioProjectData | null> => {
    const projectRef = doc(this.firestore, `devrio_projects/${projectId}`);
    const projectSnap = await getDocFromServer(projectRef);
    console.log("Doc form server", projectSnap.data());
    if (projectSnap.exists()) {
      return projectSnap.data() as IDevrioProjectData;
    }
    return null;
  };
}

const config: FirebaseOptions = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

let firebaseServiceInstance: FirebaseService;

const initFirebaseService = (): FirebaseService => {
  if (!firebaseServiceInstance) {
    firebaseServiceInstance = new FirebaseService(config);
  }
  return firebaseServiceInstance;
};

export default initFirebaseService();
