import { Dispatch, SetStateAction, createContext } from "react";

export interface ILightboxContextValue {
  lightboxImage: number;
  setLightboxImage: Dispatch<SetStateAction<number>>;
}

export const LightboxContext = createContext<ILightboxContextValue>({
  lightboxImage: -1,
  setLightboxImage: (val: SetStateAction<number>): void => {
    return;
  },
});
