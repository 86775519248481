import { getApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { ChangeEvent, MouseEvent, useState } from "react";
import firebase from "../firebase";

const WorkflowSingin = (): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  return (
    <div className="workflow-workspace">
      <div className="workflow-content">
        <h2>Workflow Tester Sign in</h2>
        <p>Devrio &amp; Brainvire Teams:</p>
        <form action="">
          <div className="workflow-form-section">
            <label htmlFor="email">
              Email
              <input
                type="text"
                name="email"
                id="email"
                onChange={(evt: ChangeEvent<HTMLInputElement>): void => {
                  setEmail(evt.target.value);
                }}
              />
            </label>
          </div>
          <div className="workflow-form-section">
            <label htmlFor="password">
              Password:
              <input
                type="password"
                name="password"
                id="password"
                onChange={(evt: ChangeEvent<HTMLInputElement>): void => {
                  setPassword(evt.target.value);
                }}
              />
            </label>
          </div>
          <div className="workflow-form-section">
            <button
              type="button"
              className="button"
              onClick={(evt: MouseEvent<HTMLButtonElement>): void => {
                evt.preventDefault();
                signInWithEmailAndPassword(
                  getAuth(getApp()),
                  email,
                  password
                ).catch((error: any) => {
                  alert(`Authentication error ${error.toString()}`);
                });
              }}
            >
              Sign In
            </button>
          </div>
          <div className="workflow-form-section">
            <hr />
            <p>Brand3D Staff Only:</p>
            <p>
              <button
                type="button"
                className="button"
                onClick={(evt: MouseEvent<HTMLButtonElement>): void => {
                  evt.preventDefault();
                  firebase.loginWithGoogle();
                }}
              >
                Sign In With Google Auth
              </button>
            </p>
          </div>
        </form>
      </div>
      <div className="workflow-content"></div>
      <div className="workflow-content"></div>
    </div>
  );
};

export default WorkflowSingin;
